/*
 * @Author: [you name]
 * @Date: 2021-09-29 17:55:44
 * @LastEditors: [you name]
 * @LastEditTime: 2021-10-08 10:37:28
 * @Description:
 */
import { createStore } from "vuex";
const keepLiveRoute = ["InquriyContent"];
// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      getQuoteParam: {}, //获取报价的参数
      setQuoteResult: {}, //报价结果
      getCarModel: {}, //选择的车型
      hasIntentionCar: true, //是否有意向车型
      keepLiveRoute,
    };
  },
  mutations: {
    setQuoteParam(state: any, data) {
      state.getQuoteParam = data;
    },
    setQuoteResult(state: any, data) {
      state.setQuoteResult = data;
    },
    setCarModel(state: any, data) {
      state.getCarModel = data;
    },
    setHasIntentionCar(state: any, data) {
      state.hasIntentionCar = data;
    },
    ClearKeep(state) {
      if (state.keepLiveRoute.length > 0) state.keepLiveRoute = [];
      console.log("清空缓存路由！");
    },
    SetKeep(state) {
      state.keepLiveRoute = keepLiveRoute;
      console.log("设置缓存路由！");
    },
  },
  actions: {},
});

export default store;
