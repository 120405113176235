export const QINIU_KEY_URL = "https://qiniu.itsmycar.cn/qiniu/token";
export const QINIU_BUCKET_NAME = "yiautos-website-cdn";
export const QINIU_UPLOAD_URL = "https://up-z0.qbox.me";
export const QINIU_BUCKET_URL = "https://websitedn.yiautos.com/";
export const SENSORS_URL_pord =
  "https://sensors.yiautos.com/sa?project=production";
export const SENSORS_URL_dev = "http://121.37.26.223:8106/sa?project=default";
export default {
  QINIU_KEY_URL,
  QINIU_UPLOAD_URL,
  QINIU_BUCKET_NAME,
  QINIU_BUCKET_URL,
  SENSORS_URL_pord,
  SENSORS_URL_dev,
};
