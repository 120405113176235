import { createRouter, createWebHistory } from "vue-router";
import { setTitle } from "@/utils/Common";

const routes = [
  {
    path: "/inquiry-index",
    name: "InquriyIndex",
    component: () => import("../views/inquiry/index.vue"),
    beforeRouteLeave(to: any, from: any, next: any) {
      if (to.name == "InquriyContent") {
        console.log("跳到内容页");
        to.meta.keepAlive = false;
      }
      next();
    },
  },
  {
    path: "/inquiry-content",
    name: "InquriyContent",
    component: () => import("../views/inquiry/content.vue"),
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
    beforeRouteLeave(to: any, from: any, next: any) {
      if (to.name == "car_model_picker") {
        from.meta.keepAlive = true;
      }
      next();
    },
  },
  {
    path: "/city-select",
    name: "CitySelect",
    component: () => import("../views/city-select/index.vue"),
  },
  {
    path: "/in_quotation",
    name: "in_quotation",
    component: () => import("../views/inquiry/in_quotation.vue"),
  },
  {
    path: "/fail-result",
    name: "fail-result",
    component: () => import("../views/inquiry/components/fail_result.vue"),
  },
  {
    path: "/quotation_result",
    name: "quotation_result",
    component: () => import("../views/inquiry/quotation_result.vue"),
    meta: {
      title: "推荐车型报价",
    },
  },
  {
    path: "/car_model_picker",
    name: "car_model_picker",
    component: () => import("../views/inquiry/car_model_picker.vue"),
    meta: {
      title: "车辆选择",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((guard) => {
  if (guard.meta?.title) {
    setTitle(guard.meta.title as string);
  }
});

export default router;
