<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="$store.state.keepLiveRoute"
                max="1">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<style lang="less">
#app {
}
</style>
