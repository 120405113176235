const ACCESS_TOKEN_KEY = "Blade-Auth";
const REFRESH_TOKEN_KEY = "refresh_token";
const ENUMS_KEY = "app_enums";
const CITY = "city";
const CHANNELID = "channelId";
//保存城市
export function setUserCity(city: any) {
  localStorage.setItem(CITY, city);
}
//获取城市
export function getUserCity() {
  const city = localStorage.getItem(CITY);
  return city;
}
//保存渠道id
export function setChannelId(channelId: any) {
  localStorage.setItem(CHANNELID, channelId);
}
//获取渠道id
export function getChannelId() {
  const channelId = localStorage.getItem(CHANNELID);
  return channelId;
}
//保存access_token
export function setAccessToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

//保存refresh_token
export function setRefreshToken(token: string) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

//获取access_token
export function getAccessToken() {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return token;
}

//获取refresh_token
export function getRefreshToken() {
  const token = localStorage.getItem(REFRESH_TOKEN_KEY);
  return token;
}

export function setEnums(enums: any) {
  localStorage.setItem(ENUMS_KEY, JSON.stringify(enums));
}

export function getEnums() {
  const enums: any = localStorage.getItem(ENUMS_KEY);
  return JSON.parse(enums);
}
