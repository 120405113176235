/*
 * @Author: [you name]
 * @Date: 2021-08-24 17:26:58
 * @LastEditors: [you name]
 * @LastEditTime: 2021-10-15 11:44:04
 * @Description:
 */
import numeral from "numeral";
import _ from "lodash";
import { Toast } from "vant";

// 价格格式化
export function grossFilter(value: any) {
  if (typeof value === "undefined" || value === "null") {
    return "--";
  }
  return "¥" + numeral(value).format("0,0.00");
}
// 节流
export const throttle = (fn: any, delay = 1000) => {
  let remaining = 0,
    previous: number = new Date().getTime();
  return function (...rest: any[]) {
    const now = new Date().getTime();
    remaining = now - previous;
    if (remaining > delay) {
      fn(...rest);
      previous = now; // 执行后更新 previous 值
    }
  };
};
/**
 * @Author: [you name]
 * @description: 函数加载，是否显示
 * @param {*} fn
 * @param {*} title
 * @param {*} loading 是否显示加载
 * @return {*}
 */
export interface LoadingConfig {
  title?: string;
  loading?: boolean;
  mask?: boolean;
  loadValue?: any;
}

export function loading(
  fn: Function,
  {
    title = "加载中",
    loading = true,
    mask = true,
    loadValue = undefined,
  }: LoadingConfig = {}
) {
  return function (...rest: any[]) {
    const hasLoadValue = typeof loadValue?.value === "boolean";
    if (hasLoadValue) {
      loadValue.value = true;
    }
    Toast.allowMultiple();
    let loadingToast: any;
    if (loading) {
      loadingToast = Toast.loading({
        message: title,
        forbidClick: true,
        duration: 0,
      });
    }
    fn(...rest)
      .then(() => {
        if (loading) {
          loadingToast.clear();
        }
        if (hasLoadValue) {
          loadValue.value = false;
        }
      })
      .catch((err: any) => {
        if (loading) {
          loadingToast.clear();
        }
        Toast(err?.message || err?.msg || err || "接口错误");
        if (hasLoadValue) {
          loadValue.value = false;
        }
      });
  };
}

// 设置页面标题
export function setTitle(title: string = "") {
  document.title = title;
  const length = document.querySelectorAll("iframe").length;
  // 微信浏览器 不能动态设置title问题hack
  const mobile = navigator.userAgent.toLowerCase();
  if (/iphone|ipad|ipod/.test(mobile) && !length) {
    const iframe = document.createElement("iframe");
    iframe.style.cssText = "display: none; width: 0; height:0;";
    iframe.setAttribute("src", "about: blank");
    iframe.addEventListener("load", listener.bind(null, iframe));
    document.body.appendChild(iframe);
  }
}
function listener(iframe: any) {
  setTimeout(() => {
    iframe.removeEventListener("load", listener, false);
    document.body.removeChild(iframe);
  }, 0);
}

//城市区划代码
export function translateAdcode(array: any[], code: any) {
  for (let ProvinceIndex = 0; ProvinceIndex < array.length; ProvinceIndex++) {
    const province = array[ProvinceIndex];
    if (code === province.code) {
      return province.name;
    }
    if (province.childrenList) {
      for (
        let cityIndex = 0;
        cityIndex < province.childrenList.length;
        cityIndex++
      ) {
        const city = province.childrenList[cityIndex];
        if (code === city.code) {
          return province.name + city.name;
        }
        if (city.childrenList) {
          for (
            let districtIndex = 0;
            districtIndex < city.childrenList.length;
            districtIndex++
          ) {
            const district = city.childrenList[districtIndex];
            if (code === district.code) {
              return province.name + city.name + district.name;
            }
          }
        }
      }
    }
  }
}

//格式化时间
export function formatDate(fmt: string, date: Date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),
    "m+": (date.getMonth() + 1).toString(),
    "d+": date.getDate().toString(),
    "H+": date.getHours().toString(),
    "M+": date.getMinutes().toString(),
    "S+": date.getSeconds().toString(),
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

export function nullFilter(value: any) {
  if (
    !value &&
    (typeof value === "undefined" ||
      typeof value === "object" ||
      typeof value === "string")
  ) {
    return "--";
  }
  return value;
}

/**
 *  获取 url 后面通过?传参的参数
 * @param {String} name
 */
export function getQueryString(name: string) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const url = decodeURI(window.location.href);
  const search = url.substring(url.lastIndexOf("?") + 1);
  const r = search.match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
