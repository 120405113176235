import config from "@/config";

export function initSensor () {
  console.log("初始化神策")
  const sensors = window.sensorsDataAnalytic201505;
  sensors.init({
    server_url: config.SENSORS_URL_pord,
    cross_subdomain: false,
    heatmap: {
      scroll_notice_map: "default",
    },
  });
  // TODO 填写平台名称、app 名称、版本号
  sensors.registerPage({
    platform_name: "wap",
    app_name: "渠道询报价H5页面",
    version: "1.1.0",
  });
  sensors.quick("autoTrack");
}