<template>
  <div class="page-header-contanier">
    <div class="common-header">
      <div class="left-content"
           @click="onBack">
        <van-icon name="arrow-left"
                  color="#141414" />
      </div>
      <div class="center-content">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, setup } from "vue";
import { useRouter } from "vue-router";
import { getChannelId } from "@/utils/StorageHelper.ts";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    self: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const router = useRouter();
    function onBack () {
      if (!props.self) {
        router.go(-1);
      } else {
        const id = getChannelId();
        router.push({ path: "/inquiry-index", query: { channelId: id } });
      }
    }
    return {
      onBack,
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../styles/common.less";
.page-header-contanier {
  position: fixed;
  top: 0;
  z-index: 100;
}
.common-header {
  width: 7.5rem;
  height: 0.88rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .left-content {
    position: absolute;
    left: 0.3rem;
    color: #141414;
    font-size: 0.34rem;
  }
  .center-content {
    height: 0.34rem;
    font-family: PingFangSC-Medium;
    font-size: 0.34rem;
    color: #000000;
    text-align: center;
    line-height: 0.34rem;
  }
}
</style>
