/*
 * @Author: xinzw
 * @Date: 2021-10-11 17:47:01
 * @LastEditors: xinzw
 * @LastEditTime: 2021-10-28 11:13:55
 * @Description: file content
 */
import "./utils/setRem";
import "babel-polyfill";
import "vant/lib/index.css";
import "./styles/normalize.css";
import "./styles/style.less";
import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import "vant/lib/index.css";
import store from "./store/index";
import PageHeader from "./components/PageHeader/index.vue";
import { initSensor } from "./mixins/sensors";
initSensor();
const app = createApp(App);
// Vue3 全局挂载
// @ts-ignore
// app.config.globalProperties.$sensors = window.sensorsDataAnalytic201505;
createApp(App)
  .use(router)
  .use(store)
  .use(Vant)
  .component("page-header", PageHeader)
  .mount("#app");
